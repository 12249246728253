import { Link } from '../../../navigation';

interface Props {
  label: string;
  renderIcon?: (className: string) => React.ReactElement;
  link: { href: string };
  active?: boolean;
  className?: string;
  closeMenu?: () => void;
}

export default function Item(props: Props) {
  return (
    <li className={`tw-group ${props.className}`}>
      <Link<any>
        {...props.link}
        onClick={props.closeMenu}
        className={`${
          props.active && 'tw-text-primary'
        } tw-text-base tw-no-underline tw-transition-colors tw-duration-150 tw-text-greyscale-body group-hover:tw-text-primary`}
      >
        <div className="tw-py-2 tw-flex tw-items-center tw-space-x-2 -tw-ml-6">
          <div
            className={`${
              props.active && 'tw-bg-primary'
            } tw-rounded-r-md tw-self-stretch tw-w-1 group-hover:tw-bg-primary tw-mr-3 tw-transition-colors tw-duration-200`}
          />
          {props.renderIcon &&
            props.renderIcon(
              `${
                props.active && 'tw-text-primary'
              } tw-text-greyscale-body group-hover:tw-text-primary tw-transition-colors tw-duration-150 tw-w-5 tw-h-5`,
            )}
          <p className="tw-flex-1 tw-m-0 tw-h-fit tw-pl-1">{props.label}</p>
        </div>
      </Link>
    </li>
  );
}
