import { Link } from '@/navigation';
import { usePathname, useSearchParams } from 'next/navigation';
import { PropsWithChildren } from 'react';

export default function LinkToLogin({ children }: PropsWithChildren) {
  const pathname = usePathname();
  const query = useSearchParams();
  query?.toString();
  return (
    <Link
      passHref
      href={{
        pathname: `/auth/signin`,
        query: {
          redirect: `${pathname}?${query?.toString()}`,
        },
      }}
    >
      {children}
    </Link>
  );
}
