import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Cork = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <path
      d="M2.90189 12.6403L8.63685 2.70709C9.53364 1.15381 13.3531 1.71252 17.2364 3.95449C21.1196 6.19646 23.5042 9.24043 22.6164 10.7782L16.8814 20.7114"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
    <path
      d="M16.189 12.7626C16.7337 13.0771 17.4303 12.8905 17.7448 12.3457C18.0593 11.801 17.8726 11.1044 17.3279 10.7899C16.7831 10.4754 16.0866 10.6621 15.7721 11.2068C15.4576 11.7515 15.6442 12.4481 16.189 12.7626Z"
      fill="currentColor"
    />
    <path
      d="M19.9434 9.91849C20.2736 10.1092 20.696 9.99601 20.8867 9.66574C21.0773 9.33546 20.9642 8.91314 20.6339 8.72246C20.3036 8.53178 19.8813 8.64494 19.6906 8.97521C19.4999 9.30549 19.6131 9.7278 19.9434 9.91849Z"
      fill="currentColor"
    />
    <path
      d="M10.4447 4.77597C10.6806 4.91217 10.9822 4.83135 11.1184 4.59544C11.2546 4.35953 11.1738 4.05787 10.9379 3.92167C10.702 3.78546 10.4003 3.86629 10.2641 4.1022C10.1279 4.33811 10.2088 4.63977 10.4447 4.77597Z"
      fill="currentColor"
    />
    <path
      d="M8.28154 19.4639C12.1419 21.6927 15.992 22.2511 16.881 20.7113C17.7701 19.1714 15.3614 16.1164 11.501 13.8876C7.64066 11.6588 3.79053 11.1003 2.9015 12.6402C2.01246 14.18 4.42119 17.2351 8.28154 19.4639Z"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);

export default Cork;
