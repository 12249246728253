'use client';

import { DependencyList, MutableRefObject, useEffect, useRef } from 'react';

export default function useOutsideClick(
  ref: MutableRefObject<HTMLElement | null>,
  cb: (params?: any) => any,
  deps?: DependencyList,
) {
  useEffect(() => {
    const handler = (ev: MouseEvent) => {
      if (!ref.current?.contains(ev.target as Node)) {
        cb();
      }
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, deps);

  return;
}
