'use client';

import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { FC, useMemo } from 'react';
import Avatar from 'react-avatar';
import useSession from '../../../stores/session/useSession';
import Item from './Item';
import Section from './Section';
import UserData from './UserData';
import getDrawer from './menuItems';

interface Props {
  withAvatar?: boolean;
  className?: string;
  closeMenu?: () => void;
}

export const Sections: FC<Props> = ({ withAvatar, className, closeMenu }) => {
  const t = useTranslations('common');
  const { user } = useSession();
  const pathname = usePathname();
  const menuItems = useMemo(() => getDrawer(!!user), [user]);

  return (
    <div className="tw-space-y-4">
      {user && (
        <>
          <div className={'tw-mb-4 tw-flex tw-flex-row tw-items-center'}>
            {withAvatar && (
              <Avatar
                size="42"
                round
                name={`${user.name} ${user.lastname}`}
                className="tw-font-sans tw-mr-4"
              />
            )}
            <UserData
              name={user.name as string}
              lastname={user.lastname as string}
              email={user.email as string}
            />
          </div>
        </>
      )}

      <ul
        className={`tw-space-y-4 tw-pl-0 tw-mb-0 ${
          !user && '-tw-mt-3'
        } ${className}`}
      >
        {menuItems.map((section) => (
          <Section
            key={section.label}
            data={section.items}
            keyExtractor={(item) => item.label}
            label={section.label && t(`drawer.${section.label}`)}
            renderItem={({ Icon, label, link }) => (
              <Item
                key={label}
                active={link.href === pathname}
                label={t(`drawer.${label}`)}
                renderIcon={(className) => <Icon className={className} />}
                link={link}
                closeMenu={closeMenu}
              />
            )}
          />
        ))}
      </ul>
    </div>
  );
};
