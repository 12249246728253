import { SearchProduct } from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CartItem } from './types';

export interface CartState {
  items: CartItem[];
  gift: boolean;
  removeItem: (product: SearchProduct, quantity?: number) => void;
  emptyCart: () => void;
  setIsGift: (value: boolean) => void;
  addItem: (product: SearchProduct) => void;
}

const useCartStore = create<CartState>()(
  persist(
    (set, get) => ({
      items: [],
      gift: false,
      addItem: (product: SearchProduct) => {
        const idx = get().items.findIndex((p) => p.product.id == product.id);
        if (idx === -1) {
          return set((state) => ({
            items: [...state.items, { product, quantity: 1 }],
          }));
        }
        set((state) => ({
          items: state.items.map((p, i) =>
            i === idx ? { ...p, quantity: p.quantity + 1 } : p,
          ),
        }));
      },
      removeItem: (product: SearchProduct, quantity?: number) => {
        const item = get().items.find((p) => p.product.id == product.id);
        if (!item) {
          return;
        }
        set((state) => ({
          items: state.items
            .map((i) =>
              i.product.id === item.product.id
                ? {
                    ...i,
                    quantity: quantity ? i.quantity - quantity : 0,
                  }
                : i,
            )
            .filter((item) => item.quantity > 0),
        }));
      },
      emptyCart: () => set(() => ({ items: [] })),
      setIsGift: (gift: boolean) => set({ gift }),
    }),
    {
      name: 'cart-storage',
    },
  ),
);

export default useCartStore;
