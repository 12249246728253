import { SVGProps } from 'react';
import {
  Bag,
  Briefcase,
  Card,
  Cart,
  Chrono,
  Cork,
  Gift,
  Grape,
  Hearth,
  Location,
  People,
  Plane,
  SimpleBag,
  Ticket,
  User,
  Wallet,
} from 'src/components/icons';

interface Section {
  label: string;
  items: Item[];
  authOnly?: boolean;
}

interface Item {
  label: string;
  link: { href: string };
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  authOnly?: boolean;
}

const drawer: Section[] = [
  {
    label: '',
    items: [
      {
        label: 'item.details',
        link: { href: '/profile' },
        Icon: User,
      },
      {
        label: 'item.orders',
        link: {
          href: '/profile/orders',
        },
        Icon: Bag,
      },
      {
        label: 'item.recentPurchases',
        link: {
          href: '/profile/recently-purchased',
        },
        Icon: Chrono,
      },
      {
        label: 'item.favorites',
        link: {
          href: '/profile/wishlist',
        },
        Icon: Hearth,
      },
      {
        label: 'item.giftcard',
        link: {
          href: '/profile/giftcards',
        },
        Icon: Ticket,
      },
      {
        label: 'item.paymentMethods',
        link: {
          href: '/profile/payment-methods',
        },
        Icon: Card,
      },
      {
        label: 'item.addresses',
        link: {
          href: '/profile/addresses',
        },
        Icon: Location,
      },
    ],
    authOnly: true,
  },
  {
    label: 'section.buy',
    items: [
      {
        label: 'item.shop',
        link: {
          href: '/shop',
        },
        Icon: Cart,
      },
      {
        label: 'item.experiences',
        link: {
          href: '/experience',
        },
        Icon: Grape,
      },
      // {
      //   label: 'item.makeAGift',
      //   link: {
      //     href: '/fai-un-regalo',
      //   },
      //   Icon: Gift,
      // },
    ],
  },
  {
    label: 'section.wallet',
    items: [
      {
        label: 'item.corkBank',
        link: {
          href: '/wallet',
        },
        Icon: Wallet,
      },
      {
        label: 'item.corkShop',
        link: {
          href: '/wallet/shop',
        },
        Icon: Cork,
      },
      {
        label: 'item.referral',
        link: {
          href: '/wallet#referral',
        },
        Icon: Plane,
      },
    ],
  },
  {
    label: 'section.misc',
    items: [
      {
        label: 'item.franchising',
        link: {
          href: '/diventa-nostro-affiliato',
        },
        Icon: People,
      },
      // {
      //   label: 'item.forBusiness',
      //   link: { href: '/business' },
      //   Icon: Briefcase,
      // },
      // {
      //   label: 'item.corporateGifting',
      //   link: {
      //     href: '/business/eventi-aziendali/regalistica-aziendale',
      //   },
      //   Icon: Gift,
      // },
      {
        label: 'item.sellWithUs',
        link: { href: '/produttori' },
        Icon: SimpleBag,
      },
    ],
  },
];

function filterOutAuthOnly(drawer: Section[]) {
  return drawer.reduce<Section[]>((filteredDrawer, section) => {
    if (section.authOnly) {
      return filteredDrawer;
    }

    filteredDrawer.push({
      ...section,
      items: section.items.filter((item) => !item.authOnly),
    });

    return filteredDrawer;
  }, [] as Section[]);
}

export default function getDrawer(isAuth: boolean): Section[] {
  if (isAuth) {
    return drawer;
  }

  return filterOutAuthOnly(drawer);
}
