import { SVGProps } from 'react';

const SvgIcon = ({ children, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={1.5}
    {...props}
  >
    {children}
  </svg>
);

export default SvgIcon;
