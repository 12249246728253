import { Link } from '../../../navigation';

interface Props {
  name: string;
  lastname: string;
  email: string;
}

export default function UserData(props: Props) {
  return (
    <Link href="/profile" className="tw-no-underline tw-min-w-0">
      <div>
        <h6 className="tw-text-title tw-text-xl tw-font-bold tw-mb-1 tw-truncate">{`${props.name} ${props.lastname}`}</h6>
        <span className="tw-text-sm tw-text-greyscale-placeholder tw-truncate tw-block">
          {props.email}
        </span>
      </div>
    </Link>
  );
}
