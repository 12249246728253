import {IMGPW} from '@/config';
import {Link} from '@/navigation';
import Image from 'next/image';
import {Close} from 'src/components/icons';

interface Props {
  onCloseMenu: () => void;
}

export default function Header(props: Props) {
  return (
    <div className="sm:tw-hidden tw-bg-white tw-border-b tw-border-b-greyscale-separator tw-px-2 tw-flex tw-justify-between">
      <Link href="/">
        <div className="tw-p-4">
          <div className="tw-h-10 tw-relative tw-w-32">
            <Image
              fill
              style={{objectFit: 'contain'}}
              src={IMGPW + '/logo_de.png'}
              alt="winelivery logo"
              className="tw-object-contain"
            />
          </div>
        </div>
      </Link>
      <div className="tw-flex">
        <Close
          onClick={props.onCloseMenu}
          className="tw-cursor-pointer tw-self-center tw-w-12 tw-h-12 tw-p-2 tw-rounded-full hover:tw-bg-greyscale-separator"
        />
      </div>
    </div>
  );
}
