import { ChevronDown, ChevronUp } from 'src/components/icons';

interface Props {
  showTopButton: boolean;
  showBottomButton: boolean;
  scrollToTop: () => void;
  scrollToBottom: () => void;
}

export default function ScrollButtons(props: Props) {
  return (
    <div className="tw-hidden sm:tw-block">
      {props.showTopButton && (
        <div
          onClick={props.scrollToTop}
          className="tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer"
        >
          <div className="tw-m-4 tw-p-1 tw-bg-greyscale-background hover:tw-bg-greyscale-pink hover:tw-text-primary tw-rounded-full tw-shadow-sm  tw-shadow-black/10">
            <ChevronUp />
          </div>
        </div>
      )}
      {props.showBottomButton && (
        <div
          onClick={props.scrollToBottom}
          className="tw-absolute tw-bottom-0 tw-right-0 tw-rounded-b-lg tw-cursor-pointer"
        >
          <div className="tw-m-4 tw-p-1 tw-bg-greyscale-background hover:tw-bg-greyscale-pink hover:tw-text-primary tw-rounded-full tw-shadow-sm tw-shadow-black/10">
            <ChevronDown className="tw-translate-y-px" />
          </div>
        </div>
      )}
    </div>
  );
}
