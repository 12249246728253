import useOutsideClick from '../../hooks/useOutsideClick';
import { PropsWithChildren, useRef } from 'react';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  onCloseMenu: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function FlyoutMenu(props: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(
    ref,
    () => {
      props.onCloseMenu();
    },
    [],
  );

  if (!props.isOpen) {
    return null;
  }

  return (
    <div className="tw-absolute tw-pb-1 tw-top-0 sm:tw-top-full tw-right-0 sm:tw-my-8 tw-w-screen sm:tw-w-fit tw-h-screen sm:tw-h-fit tw-overflow-hidden shadow sm:tw-rounded-xl tw-bg-gradient-to-r tw-from-primary tw-to-[#ef5989] tw-z-50">
      <div
        ref={ref}
        className="tw-h-full sm:tw-h-auto sm:tw-rounded-b-xl tw-bg-greyscale-background tw-flex tw-flex-col sm:tw-block"
      >
        {props.header}
        {props.children}
        {props.footer}
      </div>
    </div>
  );
}
