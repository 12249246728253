import { Button } from 'src/components/design-system';
import { useTranslations } from 'next-intl';
import { MouseEvent } from 'react';
import LinkToLogin from '../../commons/LinkToLogin';

interface Props {
  isAuth: boolean;
  openDrawer: () => void;
  handleLogout: (e: MouseEvent) => void;
}

export default function Footer(props: Props) {
  const t = useTranslations('common');
  return (
    <div className="tw-space-y-4 tw-p-6">
      {!props.isAuth ? (
        <LinkToLogin>
          <Button type="link" onClick={props.openDrawer} className="tw-w-full">
            {t('drawer.login')}
          </Button>
        </LinkToLogin>
      ) : (
        <Button
          onClick={(e) => {
            props.openDrawer();
            props.handleLogout(e);
          }}
          theme="primary"
          className="tw-w-full"
          palette="gray"
        >
          <span>Logout</span>
        </Button>
      )}
    </div>
  );
}
