import { useState } from 'react';
import Avatar from 'react-avatar';
import useSession from '../../stores/session/useSession';
import DrawerAccount from '../drawers/DrawerAccount';
import { Menu } from '../icons';

function ButtonAccount() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSession();

  return (
    <>
      <button
        className={`
          tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-10 tw-w-10
          ${
            !!user
              ? 'tw-bg-greyscale-placeholder tw-text-greyscale-offwhite'
              : 'tw-text-greyscale-body'
          }
        `}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {!!user ? (
          <Avatar
            size="40"
            round
            name={`${user.name} ${user.lastname}`}
            className="tw-font-sans"
          />
        ) : (
          <Menu className="tw-h-6 tw-w-6" />
        )}
      </button>
      <DrawerAccount
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
      />
    </>
  );
}

export default ButtonAccount;
