import { Fragment } from 'react';

interface Props<T> {
  label: string;
  data: T[];
  renderItem: (item: T) => React.ReactElement;
  keyExtractor: (item: T) => React.Key;
}

export default function Section<T>(props: Props<T>) {
  return (
    <section>
      {props.label && (
        <h3 className="tw-mb-1 tw-mt-2 tw-text-base tw-font-medium tw-text-greyscale-placeholder tw-tracking-tight">
          {props.label}
        </h3>
      )}

      {props.data.map((item) => (
        <Fragment key={props.keyExtractor(item)}>
          {props.renderItem(item)}
        </Fragment>
      ))}
    </section>
  );
}
