import { useEffect, useRef, useState } from 'react';
import ScrollButtons from './ScrollButtons';
import { Sections } from './Sections';

export default function Content({ closeMenu }: { closeMenu: () => void }) {
  const ref = useRef<HTMLDivElement>(null);

  const [isScrolledTop, setIsScrolledTop] = useState<boolean>(true);
  const [isScrolledBottom, setIsScrolledBottom] = useState<boolean>(false);

  const handleScroll = (div: HTMLDivElement) => {
    setIsScrolledTop(div.scrollTop <= 0);
    setIsScrolledBottom(div.scrollTop + div.offsetHeight >= div.scrollHeight);
  };

  useEffect(() => {
    handleScroll(ref.current!);
  }, []);

  const handleScrollToTop = () => {
    ref.current!.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToBottom = () => {
    ref.current!.scrollTo({
      top: ref.current!.scrollHeight - ref.current!.offsetHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="tw-relative tw-contents sm:tw-block">
      <ScrollButtons
        showTopButton={!isScrolledTop}
        showBottomButton={!isScrolledBottom}
        scrollToBottom={handleScrollToBottom}
        scrollToTop={handleScrollToTop}
      />
      <div
        ref={ref}
        onScroll={(e) => handleScroll(e.currentTarget)}
        className="sm:tw-max-h-[65vh] sm:tw-w-80 tw-px-6 tw-pt-5 tw-pb-4 tw-overflow-auto sm:tw-rounded-b-xl tw-bg-white tw-flex-1"
      >
        <Sections closeMenu={closeMenu} />
      </div>
    </div>
  );
}
